<template>
  <div>
    <sprint-card-add-new
      :is-add-new-sprint-sidebar-active.sync="isAddNewSprintSidebarActive"
      :project-options="projectOptions"
    />
    <b-row>
      <!-- Search -->
      <b-col cols="12" md="12">
        <div
          v-bind:class="{
            'align-items-center justify-content-end': isActiveCards,
            'd-flex align-items-center justify-content-end': !isActiveCards,
          }"
        >
          <b-input-group class="mr-1">
            <b-form-input
              v-model="searchQuery"
              @keyup.enter="searchWord()"
              :placeholder="$t('message.bookmark.search')"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="searchWord()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <br v-if="isActive" />
          <div
            v-bind:class="{
              'd-flex align-items-center': !isActiveCards,
              'd-flex align-items-center justify-content-end': isActiveCards,
            }"
          >
            <div>
              <div class="ecommerce-header-items">
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  <template slot="button-content">
                    <feather-icon icon="FilterIcon" />
                  </template>
                  <b-dropdown-item @click="filterSprint('All')">
                    {{ $t("message.projects.all") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterSprint('Not Started')">
                    Not Started
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterSprint('Sprint Planning')">
                    Sprint Planning
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterSprint('Running')">
                    Running
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterSprint('Sprint Review')">
                    Sprint Review
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="filterSprint('Sprint Retrospective')"
                  >
                    Sprint Retrospective
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterSprint('Finished')">
                    Finished
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <b-form-radio-group
              v-model="itemView"
              class="ml-1 mr-1 list item-view-radio-group"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" />
              </b-form-radio>
            </b-form-radio-group>

            <b-button
              variant="primary"
              @click="isAddNewSprintSidebarActive = true"
              v-if="restrictions('button_new_sprint')"
            >
              <span class="text-nowrap">{{
                $t("message.buttons.newSprint")
              }}</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <p style="font-size: 0.875rem">{{$t("resultSprintPercentage")}}: {{formatExecutionDate(lastExecutionPercentage)}}</p>

    <section v-if="sprintList.length === 0 && !isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <strong> {{ $t("message.registerNotFound") }}</strong>
      </div>
    </section>

    <section v-if="isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t("message.load") }}... </strong>
      </div>
    </section>

    <!-- Sprints -->
    <section v-else class="wishlist-items" :class="itemView">
      <b-card
        v-for="sprint in sprintList"
        :key="sprint.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Sprint Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body d-flex"
              style="gap: 0.5rem"
              :to="{ name: 'apps-sprint-view', params: { id: sprint.id } }"
            >
              <h3 :id="'sprint-name-' + sprint.id" style="max-width: 245px">{{ sprint.subject }}</h3>
              <b-tooltip
                v-if="sprint.subject.length > 22"
                custom-class="long-tooltip"
                :target="'sprint-name-' + sprint.id"
                triggers="hover"
                no-fade
              >
                {{ sprint.subject }}
              </b-tooltip>
                <IconLinkInternal size="12"/>
               <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
            </b-link>
          </h6>
          <b-card-text class="item-description">
            <span v-html="sprint.description.substring(0, 50) + '..'"></span>
          </b-card-text>

          <b-badge :variant="badgeColor(sprint.status)">
            {{ sprint.status }}
          </b-badge>

          <b-badge
            v-if="sprint.projectName !== 'deleted project'"
            v-bind:class="{ 'mx-1': isActive, 'mx-1': !isActive }"
            :to="{
              name: 'apps-projects-details',
              params: { id: sprint.projectId },
            }"
          >
            <feather-icon icon="LinkIcon" class="mr-25" />
            <span> {{ limitCharacters(sprint.projectName) }}</span>
          </b-badge>

          <br />
          <br />
          <div>
            <div>
              <ul class="unstyled-list list-inline" />
            </div>
            <div>
              <h6 style="font-weight: normal">
                {{ $t("message.date.start") }}
                <b>{{ localeDate(sprint.startDate) }}</b>
                {{ $t("message.date.end") }}
                <b>{{ localeDate(sprint.endDate) }}</b>
              </h6>
            </div>
          </div>

          <b-progress
            variant="primary"
            class="progress-bar-secondary mt-2 ml-0"
          >
            <b-progress-bar
              :value="sprint.percentage"
              :label="`${((sprint.percentage / 100) * 100).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="showPagination">
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
            @input="eventPage(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BSpinner,
  BDropdownItem,
  BBadge,
  BProgress,
  BProgressBar,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
//import usesprintsList from "../sprint-list/useSprintsList";
import { useShopUi } from "./useSprintCards";
import SprintCardAddNew from "../sprint-list/SprintListAddNew.vue";
import { ref } from "@vue/composition-api";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import CharacterLimit from "@core/utils/characterLimit";
import store from "@/store";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    SprintCardAddNew,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BProgress,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,
    BProgressBar,
    BTooltip,
    IconLinkInternal
  },

  data() {
    return {
      sprintList: [],
      searchQuery: "",
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      size: 16,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,
      sprintListAll: [],

      //Select
      projectOptions: [],

      //Param
      paramData: store.state.user.paramData,

      //CurrentUser
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
      isActiveCards: true,
      limitCharactersValue: 20,
      lastExecutionPercentage: ""
    };
  },

  async created() {
    this.getSprintList();
    this.onResize();
    await this.getLastExecutionJobPercentage()

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });
  },

  mounted() {
    this.$root.$on("newListSprint", (newListSprint) => {
      this.isBusy = true;

      if (AccessControl("sprint_all")) {
        axios
          .get(
            `${URL_API}sprint/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.sprintList = response.data.content;
            this.totalRegisters = response.data.totalElements;
            this.perPage = response.data.numberOfElements;
            this.currentPage = 1;
            this.isBusy = false;
          });
      } else {
        axios
          .get(
            `${URL_API}sprint/${this.userData.id}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.sprintList = response.data.content;
            this.totalRegisters = response.data.totalElements;
            this.perPage = response.data.numberOfElements;
            this.currentPage = 1;
            this.isBusy = false;
          });
      }
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  setup() {
    const isAddNewSprintSidebarActive = ref(false);

    const { itemView, itemViewOptions } = useShopUi();

    return {
      itemView,
      itemViewOptions,
      isAddNewSprintSidebarActive,
    };
  },

  methods: {
  async getLastExecutionJobPercentage() {
    const jobPercentageId = "023"
    try {
      const response = await axios.get(
        `${URL_API}job/${jobPercentageId}`,
        { headers: getHeader(this.userData) }
      );
      this.lastExecutionPercentage = response.data.body.LAST_EXECUTION
    } catch (e) {
    }
  },
    restrictions(value) {
      return AccessControl(value);
    },

    limitCharacters(value) {
      return CharacterLimit(value, this.limitCharactersValue);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1680) {
        this.isActive = false;
        this.limitCharactersValue = 20;
      } else {
        this.isActive = true;
        this.limitCharactersValue = 15;
      }

      this.isActiveCards = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t(colName);
    },

    async getAllSprintList() {
      this.isBusy = true;
      this.showPagination = false;

      if (AccessControl("sprint_all")) {
        await axios
          .get(
            `${URL_API}sprint/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.sprintListAll = response.data.content;
          });
      } else {
        await axios
          .get(
            `${URL_API}sprint/${this.userData.id}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.sprintListAll = response.data.content;
          });
      }
    },

    async getSprintList() {
      if (AccessControl("sprint_all")) {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(
            `${URL_API}sprint/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.sprintList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });
      } else {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(
            `${URL_API}sprint/${this.userData.id}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.sprintList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });
      }

      if (this.sprintList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      if (AccessControl("sprint_all")) {
        await axios
          .get(
            `${URL_API}sprint/list-dto?page=${currentPage - 1}&sort=id,${
              this.paramData.listSort
            }&size=${this.paramData.cardSize}`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.sprintList = response.data.content;
            this.isBusy = false;
          });
      } else {
        await axios
          .get(
            `${URL_API}sprint/${this.userData.id}/list-dto?page=${
              currentPage - 1
            }&sort=id,${this.paramData.listSort}&size=${
              this.paramData.cardSize
            }`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.sprintList = response.data.content;
            this.isBusy = false;
          });
      }

      if (this.sprintList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getSprintFilter() {
      if (AccessControl("sprint_all")) {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(`${URL_API}sprint/search?searchTerm=${this.searchQuery}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.sprintList = [];
            if (response.data != []) {
              for (var i = 0; i < response.data.length; i++) {
                this.sprintList.push(response.data[i]);
              }
            }
            this.isBusy = false;
            this.showPagination = false;
          });
      } else {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(
            `${URL_API}sprint/${this.userData.id}/search?searchTerm=${this.searchQuery}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.sprintList = [];
            if (response.data != []) {
              for (var i = 0; i < response.data.length; i++) {
                this.sprintList.push(response.data[i]);
              }
            }
            this.isBusy = false;
            this.showPagination = false;
          });
      }
    },

    searchWord() {
      if (this.searchQuery) {
        this.getSprintFilter();
      } else {
        this.getSprintList();
      }
    },
    formatExecutionDate(value) {
      if(!value) return this.$i18n.t("message." + "noDate");
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
    },
    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    badgeColor(value) {
      if (value === "Not Started") {
        return "secondary";
      }
      if (value === "Sprint Planning") {
        return "info";
      }
      if (value === "Running") {
        return "primary";
      }
      if (value === "Sprint Review") {
        return "warning";
      }
      if (value === "Sprint Retrospective") {
        return "dark";
      }
      if (value === "Finished") {
        return "success";
      }
      return "secondary";
    },

    async filterSprint(status) {
      this.sprintList = [];
      this.isBusy = true;

      if (status === "All") {
        await this.getSprintList();
      } else {
        if (AccessControl("sprint_all")) {
          await axios
            .get(`${URL_API}sprint/filter-all/${status}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.sprintList = response.data;
              this.isBusy = false;
            });
        } else {
          await axios
            .get(`${URL_API}sprint/filter-user/${this.userData.id}/${status}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.sprintList = response.data;
              this.isBusy = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.long-tooltip::v-deep .tooltip-inner {
  max-width: 45rem;
}
</style>
